import React from "react"

export const CustomButtonUnSelect = ({ setData, Data, DataValue }) => {
  return (
    <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
      onClick={() => {
        if (Data == DataValue)
          setData("")
        else
          setData(DataValue)
      }}
    >
      <div className={Data == DataValue ? "active-option" : "passive-option"}>
        {DataValue}
      </div>
    </button>
  )
}